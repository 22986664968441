<template>
  <div class="my-reading">
    <div class="myr-th">
      <div class="myr-time">Time</div>
      <div class="myr-activity">Activity</div>
    </div>
    <el-scrollbar height="720px">
      <ul  v-if  = "activities.length !== 0">
        <li  v-for = "(activity, index) in activities" :key = "index">
          <div class = "myr-time">{{ dateFormat(activity.timeAdded) }}</div>
          <div class = "myr-activity">
            {{ activity.activity }}</div>
        </li>
      </ul>
      <el-empty v-else description = "No Data"/>
    </el-scrollbar>
    
  </div>
</template>

<script>
import {ref} from "vue";
import {useRoute} from "vue-router";
import {formatDateTime} from "@/utils/dateFormatter";
import {get} from "@/api";

export default {
  name: "ReadingHistory",
  setup() {
    const activities = ref(0);
    const route = useRoute();
    const names = ref([]);

    function dateFormat(date) {
      let old = new Date(date)
      return formatDateTime(old, 'yyyy-MM-dd HH:mm:ss')
    }

    function load() {
      get('/activity/get/' + route.query.uid).then(res => {
        activities.value = res.activities
      })
    }
    load()

    return{
      activities,
      names,
      dateFormat
    }
  }
}
</script>

<style scoped>
.my-reading{
  display: block;
  padding: 20px 25px;
}
.my-reading .myr-th{
  display: flex;
  font-family: "Poppins-SemiBold";
  font-size: 18px;
  color: #111880;
  padding: 20px 0;
  border-bottom: 1px solid #DADADA;
}
.my-reading .myr-th .myr-time{
  text-align: left;
  padding-left: 45px;
}
.my-reading .myr-time{
  width: 23%;
  text-align: center;
}
.my-reading .myr-activity{
  width: 77%;
  text-align: left;
}
.my-reading ul{
  display: block;
  height: 475px;
  /* overflow-y: overlay; */
  -webkit-overflow-scrolling: touch;
}
.my-reading ul::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
  position: absolute;
}
.my-reading ul::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 5px;
}
.my-reading li{
  display: flex;
  font-size: 14px;
  font-family: "Poppins-Medium";
  line-height: 1.5;
  padding: 20px 0;
  border-bottom: 1px solid #DADADA;
}
</style>